import React, { useState, useEffect, useRef } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CRow,
  CForm,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
  CAlert,
} from "@coreui/react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Versioning from "../../../common/versioning";
import Cryptr from "cryptr";
import {
  b64DecodeUnicode,
  b64EncodeUnicode,
  getUrlSite,
  updateToken,
} from "../../../helpers/Helpers";
import logo from "../../../assets/img/brand/logo.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import App from "../../../common/App";

library.add(fas, fab);

const cookies = new Cookies();

/**
 * Questa vista permetta la visualizzazione della pagina di login per l'accesso
 * al personale autorizzato. <br>
 * <hr>
 *
 * I dati per effettuare il login vengono richiamati dall'helper (Helper.js) <br>
 *
 * @property {object} location Oggetto JSON che contiene l'URL della maschera compresi i parametri
 * <pre><div style="line-height:1.3em">
 * {
 *  "location": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 * @property {object} history Oggetto JSON che contiene la history del browser
 * <pre><div style="line-height:1.3em">
 * {
 *  "history": {
 *     "state":{
 *       "from": {
 *         "pathname": "value"
 *       }
 *     }
 *   }
 * }
 * </div></pre>
 * @category viste-standard
 * @subcategory pages
 */

const Login = (props) => {
  const [usernameValue, setUsername] = useState("");
  const [passwordValue, setPassword] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(
    cookies.get("JEProjectJWTTK") !== undefined
  );
  const [resultValue, setResult] = useState("");
  const [usernameErrato, setUsernameErrato] = useState(false);
  const [passwordErrata, setPasswordErrata] = useState(false);
  const [modalVarco, setModalVarco] = useState(false);
  const [varcoSettings, setVarcoSettings] = useState(
    localStorage.getItem("JEProjectVarco") !== null
      ? localStorage.getItem("JEProjectVarco")
      : {}
  );
  const [capsLockIsEnabled, setCapsLockIsEnabled] = useState({
    enabled: false,
    input: "",
  });
  const [togglePassword, setTogglePassword] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (redirectToReferrer === true) {
      history.push(from);
    } else if (
      localStorage.getItem("JEProjectVarco") !== null &&
      process.env.REACT_APP_ALLOW_VARCHI === "true"
    ) {
      let optionsVarco = JSON.parse(
        b64DecodeUnicode(localStorage.getItem("JEProjectVarco"))
      );
      history.push(
        "/MonitorAccessiVarco/:" +
          optionsVarco.nVarco +
          "&" +
          optionsVarco.posNFC
      );
    } else {
      checkUUIDError();
    }
  }, []);

  const checkUUIDError = () => {
    if (cookies.get("JEProjectUUIDError")) {
      let cryptr = new Cryptr(process.env.REACT_APP_ENCRYPTDBINFO);
      const UUIDError = cryptr.decrypt(cookies.get("JEProjectUUIDError"));
      if (UUIDError === "true") {
        setResult(
          "Accesso rilevato da un altra macchina, eseguire nuovamente il login."
        );
      }
      cookies.remove("JEProjectUUIDError");
    }
  };

  /**
   * Metodo handler per la gestione del login
   */
  const handleLogin = () => {
    callLogin();
  };

  /**
   * Metodo handler per la gestione del submit del form
   * @param {object} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  /**
   * Metodo per la chiamata al servizio di login
   */
  const callLogin = () => {
    var username = usernameValue;
    var password = passwordValue;
    var durataToken =
      process.env.REACT_APP_NDAYS_COOKIE > 0
        ? process.env.REACT_APP_NDAYS_COOKIE
        : 1;
    let redirectPath = null;
    let encodedRouteParameters = null;
    let pathname = null;

    pathname = location.state?.from?.pathname || "/";

    if (pathname != null) {
      let ar = pathname.split(":");
      encodedRouteParameters = ar[1];
      if (ar[0].startsWith("/")) {
        redirectPath = ar[0].substring(1, ar[0].length);
      }
      if (redirectPath.endsWith("/")) {
        redirectPath = redirectPath.substring(0, redirectPath.length - 1);
      }
    }

    if (cookies.get("JEProjectUUIDError")) {
      cookies.remove("JEProjectUUIDError");
    }

    updateToken(
      "JEProjectJWTTK",
      durataToken,
      "JEProjectTMP",
      {
        username,
        password,
      },
      props.history,
      redirectPath,
      encodedRouteParameters,
      function (error) {
        setResult(error);
      }
    );
  };

  /**
   * Metodo per l'aggiornamento dello stato del campo username
   * @param {unknown} evt
   */
  const updateUsernameValue = (evt) => {
    setUsername(evt.target.value);
  };

  /**
   * Metodo per l'aggiornamento dello stato del campo password
   * @param {unknown} evt
   */
  const updatePwdValue = (evt) => {
    setPassword(evt.target.value);
  };

  const saveVarcoSettings = () => {
    if (
      varcoSettings.nVarco !== undefined &&
      varcoSettings.posNFC !== undefined
    ) {
      localStorage.setItem(
        "JEProjectVarco",
        b64EncodeUnicode(JSON.stringify(varcoSettings))
      );

      // const maxDate = new Date();
      // maxDate.setFullYear(maxDate.getFullYear() + 10);
      // cookies.set(
      //   "JEProjectVarco",
      //   b64EncodeUnicode(JSON.stringify(varcoSettings)),
      //   { maxAge: maxDate, path: "/" }
      // );

      setModalVarco(false);
      history.push(
        getUrlSite() + "/:" + varcoSettings.nVarco + "&" + varcoSettings.posNFC
      );
    }
  };

  /**
   * Metodo utilizzato per capire se il caps lock è attivo
   * @property {object} e Evento di tastiera
   * @property {string} input Campo di input
   */
  const onKeyCapsChanged = (e, input) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLockIsEnabled({ enabled: true, input: input });
    } else {
      setCapsLockIsEnabled({ enabled: false, input: input });
    }
  };

  /**
   * @returns {Login}
   */
  return (
    <>
      <div className='bg-white min-vh-100 d-flex flex-row align-items-center'>
        <CContainer className='loginPage'>
          <CRow className='justify-content-center'>
            <CCol md='6' lg='4'>
              <CCard>
                <CCardBody>
                  <CForm onSubmit={(event) => handleSubmit(event)}>
                    <CRow className='justify-content-center'>
                      <CCol xs='8'>
                        <img
                          src={logo}
                          className='img-fluid center-block'
                          style={{ width: "100%" }}
                          alt='Logo Aziendale'
                        />
                      </CCol>
                    </CRow>
                    <p className='text-muted text-center'>
                      Accedi al tuo account
                    </p>

                    <CInputGroup className='mb-2 rounded-bottom'>
                      <CInputGroupText>
                        <FontAwesomeIcon icon={["fas", "user"]} />
                      </CInputGroupText>

                      <CFormInput
                        type='text'
                        placeholder='Username'
                        value={usernameValue}
                        className='z-1 '
                        onClick={(e) => onKeyCapsChanged(e, "username")}
                        onKeyUp={(e) => onKeyCapsChanged(e, "username")}
                        onBlur={() => setCapsLockIsEnabled(false)}
                        onChange={updateUsernameValue}
                      />
                      {capsLockIsEnabled.enabled &&
                        capsLockIsEnabled.input === "username" && (
                          <div
                            className='valid-tooltip d-block bg-warning'
                            style={{ opacity: 0.9 }}
                          >
                            Il Caps Lock è attivo!
                          </div>
                        )}
                    </CInputGroup>

                    <CInputGroup className='mb-4'>
                      <CInputGroupText>
                        <FontAwesomeIcon icon={["fas", "lock"]} />
                      </CInputGroupText>
                      <CFormInput
                        type={togglePassword ? "text" : "password"}
                        placeholder='Password'
                        onClick={(e) => onKeyCapsChanged(e, "password")}
                        onKeyUp={(e) => onKeyCapsChanged(e, "password")}
                        onBlur={() => setCapsLockIsEnabled(false)}
                        onChange={updatePwdValue}
                      />
                      <CInputGroupText
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          !togglePassword
                            ? setTogglePassword(true)
                            : setTogglePassword(false);
                        }}
                      >
                        {togglePassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </CInputGroupText>
                      {capsLockIsEnabled.enabled &&
                        capsLockIsEnabled.input === "password" && (
                          <div
                            className='valid-tooltip d-block bg-warning'
                            style={{ opacity: 0.9 }}
                          >
                            Il Caps Lock è attivo!
                          </div>
                        )}
                    </CInputGroup>

                    <CRow className='justify-content-center'>
                      <CCol xs='6' className='d-grid gap-25'>
                        <CButton
                          /*block*/ color='primary'
                          type='submit'
                          className=''
                          onClick={(event) => handleLogin(event)}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs='12'>
                        <p className='text-muted text-center'>{resultValue}</p>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs='12'>
                        <p className='text-muted text-center m-0'>
                          <Versioning className='versioneLogin' />
                        </p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
        {process.env.REACT_APP_ALLOW_VARCHI === "true" ? (
          <div className='position-fixed end-0 bottom-0'>
            <div className='m-2 p-1' onClick={() => setModalVarco(!modalVarco)}>
              <IoSettingsOutline size={"30"} />
            </div>
          </div>
        ) : null}
      </div>
      <CModal
        visible={modalVarco}
        onClose={() => setModalVarco(false)}
        aria-labelledby='ModalVarco'
      >
        <CModalHeader>
          <CModalTitle>Opzioni Varco</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormInput
              type='number'
              label='Numero Varco'
              placeholder='Inserisci il numero del varco...'
              aria-describedby='Numero Varco'
              required
              onChange={(event) =>
                setVarcoSettings({
                  nVarco: event.target.value,
                  posNFC: varcoSettings.posNFC,
                })
              }
            />
            <CFormSelect
              size='sm'
              aria-label='Numero Varco'
              className='mt-1 rounded'
              required
              options={[
                "Posizione lettore NFC",
                { label: "Alto", value: "top" },
                { label: "Destra", value: "end" },
                { label: "Basso", value: "bottom" },
                { label: "Sinistra", value: "start" },
              ]}
              onChange={(event) =>
                setVarcoSettings({
                  nVarco: varcoSettings.nVarco,
                  posNFC: event.target.value,
                })
              }
            />
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton onClick={() => setModalVarco(false)} color='secondary'>
            Chiudi
          </CButton>
          <CButton onClick={() => saveVarcoSettings()} color='success'>
            Salva e Abilita
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Login;
